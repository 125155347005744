<script>
import { Button, Table, Message } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'
import { deleteAction, getAction, postAction } from '@/command/netTool'

export default {
  name: 'rewardSetting',
  props: {
    acData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dataSource: [],
      goodsTypeArr: [],
      pointGoods: [],
      goodsList: [{ goodsType: '', goodsId: '', goodsName: '', goodsNumber: '', goodsSku: '', goodsPic: '' }],
      form: { inventory: 0, condition: 1 },
      includesData: ['', null],
      pointGoodsArr: []
    }
  },
  methods: {
    mergeCells(text, data, key, index) {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key]) {
        return 0
      }
      let rowSpan = 1
      // 判断下一行是否相等
      for (let i = index + 1; i < data.length; i++) {
        if (text !== data[i][key]) {
          break
        }
        rowSpan++
      }
      return rowSpan
    },
    onEdit({ condition }) {
      const { activityId } = this.acData
      getAction(`/activities_award/detail/${activityId}/${condition}`).then(res => {
        const { data } = res
        this.goodsList = data
        this.form.condition = Number(condition)
        this.onOpen('编辑商品', this.form)
      })
    },
    onDelete({ condition }) {
      const { activityId } = this.acData
      deleteAction(`/activities_award/delete/${activityId}/${condition}`, {}, '/api').then(res => {
        Message.success('删除成功')
        this.getInit()
      })
    },
    getColumns() {
      const { activityStatus } = this.acData

      return [
        {
          title: '签到天数',
          dataIndex: 'condition',
          align: 'center',
          customRender: (text, record, index) => {
            const obj = {
              children: text !== null ? `第${text}天` : '',
              attrs: {}
            }
            obj.attrs.rowSpan = this.mergeCells(text, this.dataSource, 'condition', index)
            return obj
          }
        },
        {
          title: '奖励商品',
          align: 'center',
          children: [
            {
              title: '商品图片',
              align: 'center',
              dataIndex: 'goodsPic',
              customRender: text => {
                return <img style="width: 24px;height:24px;" src={text} />
              }
            },
            {
              title: '商品名称',
              dataIndex: 'goodsName',
              align: 'center',
              customRender: text => {
                return <span title={text}>{text.length > 10 ? text.slice(0, 10) + '...' : text}</span>
              }
            },
            {
              title: '商品类型',
              dataIndex: 'goodsType',
              align: 'center',
              customRender: text => {
                return this.goodsTypeArr.find(f => f.value == text)?.name || ''
              }
            },
            {
              title: '数量',
              dataIndex: 'goodsNumber',
              align: 'center',
              customRender: (text, record) => {
                return `${text}${record.goodsUnit ?? ''}`
              }
            },
            {
              title: '库存',
              dataIndex: 'goodsSku',
              align: 'center'
            },
            {
              title: '操作',
              align: 'center',
              dataIndex: 'condition',
              customRender: (text, record, index) => {
                let option = (
                  <div>
                    <a style="margin-right: 10px" onClick={() => this.onEdit(record)}>
                      编辑
                    </a>
                    <a v-show={activityStatus != '1'} onClick={() => this.onDelete(record)}>
                      删除
                    </a>
                  </div>
                )

                let obj = {
                  children: option,
                  attrs: {}
                }
                obj.attrs.rowSpan = this.mergeCells(text, this.dataSource, 'condition', index)
                return obj
              }
            }
          ]
        }
      ]
    },
    getForm() {
      const { activityStatus } = this.acData
      return [
        {
          value: this.form,
          display: true,
          form: [
            {
              name: '签到天数',
              type: 'inputNumber',
              key: 'condition',
              disabled: activityStatus == '1',
              props: {
                min: 1
              },
              rules: [
                {
                  required: true,
                  type: 'number'
                }
              ]
            }
          ]
        },
        ...this.goodsList.map((e, i) => {
          return {
            display: true,
            value: e,
            form: [
              {
                type: 'row',
                cols: 24,
                name: `商品类型`,
                rules: [
                  {
                    required: true
                  }
                ],
                gutter: 20,
                children: [
                  {
                    label: `商品类型`,
                    placeholder: '请选择商品类型',
                    type: 'select',
                    key: 'goodsType',
                    cols: 20,
                    disabled: activityStatus == '1',
                    typeData: this.goodsTypeArr,
                    rules: [
                      {
                        required: true
                      }
                    ]
                  },
                  {
                    label: '删除奖励',
                    type: 'button',
                    props: {
                      disabled: this.goodsList.length == 1 || activityStatus == '1'
                    },
                    cols: 4,
                    onClick: () => {
                      this.goodsList.splice(i, 1)
                      this.$forceUpdate()
                    }
                  }
                ]
              },
              {
                name: '商品名称',
                type: 'select',
                key: 'goodsId',
                disabled: activityStatus == '1',
                typeData: this.pointGoodsArr.find(f => f.type == e.goodsType)?.list || [],
                onChange: value => {
                  let obj = (this.pointGoodsArr.find(f => f.type == e.goodsType)?.list || []).find(f => f.id == value)
                  this.goodsList[i]['goodsPic'] = obj.goodsPic || null
                  this.goodsList[i]['goodsName'] = obj.goodsName || null
                },
                rules: [
                  {
                    required: true
                  }
                ]
              },
              {
                type: 'row',
                cols: 24,
                name: `赠送数量`,
                gutter: 20,
                children: [
                  {
                    label: '赠送数量',
                    type: 'inputNumber',
                    key: 'goodsNumber',
                    cols: 18,
                    disabled: activityStatus == '1',
                    props: {
                      min: 0
                    }
                  },
                  {
                    label: '单位',
                    type: 'select',
                    key: 'goodsUnit',
                    cols: 6,
                    disabled: activityStatus == '1',
                    typeData: [
                      {
                        name: 'ml',
                        value: 'ml'
                      },
                      {
                        name: 'g',
                        value: 'g'
                      },
                      {
                        name: '张',
                        value: '张'
                      },
                      {
                        name: '个',
                        value: '个'
                      }
                    ]
                  }
                ]
              },
              {
                name: '库存',
                type: 'inputNumber',
                key: 'goodsSku',
                desc: `注：当前商品库存${(this.pointGoodsArr.find(f => f.type == e.goodsType)?.list || []).find(
                  f => f.name === e.goodsName
                )?.inventory || 0}`,
                props: {
                  min: 0,
                  max:
                    (this.pointGoodsArr.find(f => f.type == e.goodsType)?.list || []).find(f => f.name === e.goodsName)
                      ?.inventory || 0
                }
              },
              {
                name: '商品图片',
                type: 'upload',
                key: 'goodsPic',
                disabled: activityStatus == '1'
              }
            ]
          }
        }),
        {
          display: activityStatus != '1',
          form: [
            {
              label: '添加奖励',
              type: 'button',
              props: {
                style: {
                  marginLeft: '14%'
                }
              },
              onClick: () => {
                this.goodsList.push({
                  goodsType: '',
                  goodsId: '',
                  goodsName: '',
                  goodsNumber: '',
                  goodsSku: '',
                  goodsPic: ''
                })
              }
            }
          ]
        }
      ].filter(e => e.display)
    },
    onOpen(title, data) {
      apiTool.showDrawer({
        title: title,
        view: DrawerForm,
        width: '780px',
        viewProps: {
          formProps: {
            labelCol: { span: 3 },
            wrapperCol: { span: 21 }
          },
          data: this.getForm
          //   form: Object.assign({}, data)
        },
        success: ({ data, setHidden }) => {
          const { condition } = data
          const { activityId } = this.acData
          let elements = this.goodsList.map(e => {
            return {
              ...e,
              condition,
              activitiesId: activityId
            }
          })

          try {
            elements.forEach(e1 => {
              if (
                this.includesData.includes(e1.goodsType) ||
                this.includesData.includes(e1.goodsName) ||
                this.includesData.includes(e1.condition)
              ) {
                throw new Error('请完成表单填写')
              }
            })
          } catch (error) {
            Message.warning('请完成表单填写')
            return false
          }

          postAction('/activities_award/add_or_modify', { elements }, '/api').then(res => {
            Message.success('保存成功')
            setHidden()
            this.getInit()
            this.goodsList = []
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    //#region
    //   <div class="top-box">
    //     <span class="title">
    //       <span style="color:#f5222d">*</span>每日签到固定奖励：
    //     </span>
    //     <div class="title-box">
    //       <div>
    //         <a-checkbox>积分</a-checkbox>
    //         <span style={{ marginRight: '15px', marginLeft: '30px' }}>送</span>
    //         <a-input-number style={{ width: '75px' }} size="small"></a-input-number>
    //         <span style={{ marginLeft: '15px' }}>积分</span>
    //       </div>
    //       <div>
    //         <a-checkbox>成长值</a-checkbox> <span style={{ marginRight: '15px', marginLeft: '12px' }}>送</span>
    //         <a-input-number style={{ width: '75px' }} size="small"></a-input-number>
    //         <span style={{ marginLeft: '15px' }}>成长值</span>
    //       </div>
    //     </div>
    //   </div>
    // <div>
    //         <span class="title">
    //           <span style="color:#f5222d">*</span>签到额外奖励：
    //         </span>
    //         <Button type="primary" ghost={true} style={{ width: '100px' }} onClick={() => this.onOpen('添加商品')}>
    //           添加
    //         </Button>
    //       </div>
    //#endregion
    renderView() {
      const { activityStatus } = this.acData
      return (
        <div>
          <div>
            <span>签到额外奖励：</span>
            <Button
              v-show={activityStatus != '1'}
              type="primary"
              ghost={true}
              style={{ width: '100px' }}
              onClick={() => {
                this.goodsList = [
                  { goodsType: '', goodsId: '', goodsName: '', goodsNumber: '', goodsSku: '', goodsPic: '' }
                ]
                this.onOpen('添加商品')
              }}
            >
              添加
            </Button>
          </div>
          <div style="margin-top: 15px">
            <Table pagination={false} bordered columns={this.getColumns()} dataSource={this.dataSource}></Table>
          </div>
        </div>
      )
    },
    getInit() {
      const { activityId } = this.acData
      getAction('/activities_award/list/' + activityId).then(res => {
        this.dataSource = res.data
      })
    },
    getTypeData(type) {
      getAction('/point_goods/query?type=' + type).then(res => {
        let { goodsInfoList } = res.data
        this.pointGoodsArr.push({
          type: type,
          list: goodsInfoList.map(e => {
            return {
              ...e,
              name: e.goodsName,
              value: e.id
            }
          })
        })
      })
    }
  },
  mounted() {
    getAction('/point_goods/goods_types').then(res => {
      this.goodsTypeArr = res.data
      this.goodsTypeArr.forEach(e => {
        this.getTypeData(e.value)
      })
    })
    const { activityId } = this.acData
    if (activityId) {
      this.getInit()
    }
  },
  render() {
    return <div>{this.renderView()}</div>
  }
}
</script>

<style lang="less" scoped>
.top-box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 18px;

  .title-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60px;
  }
}

.title {
  width: 18%;
  text-align: right;
  display: inline-block;
  margin-right: 15px;
}
</style>
